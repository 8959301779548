// @file Helper for post properties.
import { ContentPickerSource } from '@@/bits/content_picker'
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { p__, __ } from '@@/bits/intl'
import { getCustomDateFieldStringForDisplay } from '@@/bits/time'
import type {
  Post,
  PostAttributes,
  PostCustomPropertyValue,
  UserFieldValue,
  WallCustomPostNumberProperty,
  WallCustomPostProperty,
  WallPostProperties,
} from '@@/types'
import { isEqual } from 'es-toolkit'
import { isEmpty } from 'es-toolkit/compat'

const SUBJECT_DEFAULT_PLACEHOLDER = (): string => {
  return __('Subject')
}
const SUBJECT_PLACEHOLDER_CHAR_LIMIT = 200 // Keep in sync with the constant in WallPostProperties::PostProperties
const SUBJECT_PLACEHOLDER_VALIDATION_MESSAGE = (): string => {
  return __('Subject placeholder cannot exceed %{subjectPlaceholderCharLimit} characters', {
    subjectPlaceholderCharLimit: SUBJECT_PLACEHOLDER_CHAR_LIMIT,
  })
}

const BODY_DEFAULT_PLACEHOLDER = (): string => {
  const placeholderTexts = [
    __('Write something beautiful...'),
    __('Write something fantastic...'),
    __('Write something incredible...'),
  ]
  return placeholderTexts[Math.floor(Math.random() * placeholderTexts.length)]
}
const BODY_PLACEHOLDER_CHAR_LIMIT = 2000 // Keep in sync with the constant in WallPostProperties::PostProperties
const BODY_PLACEHOLDER_VALIDATION_MESSAGE = (): string => {
  return __('Body placeholder cannot exceed %{bodyPlaceholderCharLimit} characters', {
    bodyPlaceholderCharLimit: BODY_PLACEHOLDER_CHAR_LIMIT,
  })
}

// #region Attachment settings
const ATTACHMENT_TEXT_PLACEHOLDER = (): string => {
  return __('Describe the attachment you want added')
}
const ATTACHMENT_TEXT_CHAR_LIMIT = 100 // Keep in sync with the constant in WallPostProperties::PostProperties
const ATTACHMENT_TEXT_VALIDATION_MESSAGE = (): string => {
  return __('%{attachmentTextCharLimit} characters maximum', {
    attachmentTextCharLimit: ATTACHMENT_TEXT_CHAR_LIMIT,
  })
}

const WHITESPACE_REGEXT = /^\s*$/
const hasRichAttachment = (post: PostAttributes | undefined): boolean => {
  return post?.wish_content?.attachment_props?.type != null
}

/**
 * Returns an object with all attachment settings set to the given default value.
 */
function getAttachmentSettingsObject(
  defaultValue: boolean,
): Exclude<WallPostProperties['attachment_settings'], boolean> {
  const keys = Object.values(ContentPickerSource)
  return keys.reduce<Record<string, boolean>>((obj, key) => {
    obj[key] = defaultValue
    return obj
  }, {})
}

function areAllAttachmentSettingsValuesEqual(
  attachmentSettings: WallPostProperties['attachment_settings'],
  assertValue: boolean,
): boolean {
  return isEqual(attachmentSettings, getAttachmentSettingsObject(assertValue))
}

// Length limit values are either set by the user, or falls back to values set in quotas.rb/library_quotas.rb on the backend.
// There is an implicit assumption that fallback values (eg 2 minutes, 30 minutes) are also included in this list.
export interface VideoLengthLimitOption {
  value: 15 | 30 | 45 | 60 | 120 | 180 | 300 | 600 | 900 | 1800
  text: string
  shortText: string
}

export const VideoLengthLimits: VideoLengthLimitOption[] = [
  {
    value: 15,
    text: __('15 seconds'),
    shortText: p__('short form of 15 seconds', '15 sec'),
  },
  {
    value: 30,
    text: __('30 seconds'),
    shortText: p__('short form of 30 seconds', '30 sec'),
  },
  {
    value: 45,
    text: __('45 seconds'),
    shortText: p__('short form of 45 seconds', '45 sec'),
  },
  {
    value: 60,
    text: __('1 minute'),
    shortText: p__('short form of 1 minutes', '1 min'),
  },
  {
    value: 120,
    text: __('2 minutes'),
    shortText: p__('short form of 2 minutes', '2 min'),
  },
  {
    value: 180,
    text: __('3 minutes'),
    shortText: p__('short form of 3 minutes', '3 min'),
  },
  {
    value: 300,
    text: __('5 minutes'),
    shortText: p__('short form of 5 minutes', '5 min'),
  },
  {
    value: 600,
    text: __('10 minutes'),
    shortText: p__('short form of 10 minutes', '10 min'),
  },
  {
    value: 900,
    text: __('15 minutes'),
    shortText: p__('short form of 15 minutes', '15 min'),
  },
  {
    value: 1800,
    text: __('30 minutes'),
    shortText: p__('short form of 30 minutes', '30 min'),
  },
] as const
// #endregion

const isPostEmpty = (post: PostAttributes | undefined): boolean =>
  post == null ||
  ((post.subject == null || post.subject === '' || WHITESPACE_REGEXT.test(post.subject)) &&
    (post.body == null || post.body === '' || WHITESPACE_REGEXT.test(post.body)) &&
    (post.attachment == null || post.attachment === '' || WHITESPACE_REGEXT.test(post.attachment)) &&
    !hasRichAttachment(post) &&
    !hasCustomProperties(post))

// #region Custom properties
function filterUndefinedProperties<T extends Record<string, any>>(obj: T): Partial<T> {
  return Object.keys(obj)
    .filter((key) => obj[key] != null)
    .reduce<Partial<T>>((acc, key) => {
      acc[key as keyof T] = obj[key]
      return acc
    }, {})
}

function hasSameCustomProperties(
  post1: Pick<PostAttributes, 'custom_properties'>,
  post2: Pick<PostAttributes, 'custom_properties'>,
): boolean {
  const post1DefinedCustomProperties = filterUndefinedProperties(post1.custom_properties ?? {})
  const post2DefinedCustomProperties = filterUndefinedProperties(post2.custom_properties ?? {})

  return isEqual(post1DefinedCustomProperties, post2DefinedCustomProperties)
}

function hasCustomProperties(post: PostAttributes): boolean {
  if (post.custom_properties == null) return false
  // custom_properties can be { "xcvw": undefined }
  return Object.values(post.custom_properties).some((property) => property != null && property !== '')
}

function hasAllCustomPropertiesDefined({
  wallCustomPostProperties,
  post,
}: {
  wallCustomPostProperties: WallCustomPostProperty[]
  post: PostAttributes | null
}): boolean {
  if (post == null) return false
  return wallCustomPostProperties.every((wallCustomPostProperty) => {
    if (post.custom_properties == null || wallCustomPostProperty.id == null) return false
    const postPropertyValue = post.custom_properties[wallCustomPostProperty.id]
    return !isEmpty(postPropertyValue)
  })
}

function willDeletePostAfterRemovingCustomProperty(propertyId: string, posts: Post[]): boolean {
  return posts.some((post) => {
    const postWithoutProperty = {
      ...post,
      custom_properties: { ...post.custom_properties, [propertyId]: undefined },
    }
    return isPostEmpty(postWithoutProperty)
  })
}

const postOnlyHasCustomProperties = (post: PostAttributes): boolean => {
  return hasCustomProperties(post) && isPostEmpty({ ...post, custom_properties: undefined })
}

const isWallCustomPostPropertyOfType = <T extends WallCustomPostProperty['data_type']>(
  property: WallCustomPostProperty | null,
  type: T,
): property is Extract<WallCustomPostProperty, { data_type: T }> => {
  return property?.data_type === type
}

const CUSTOM_PROPERTY_DATA_TYPE_DISPLAY_TEXT = (): Record<WallCustomPostProperty['data_type'], string> => {
  return {
    text: __('Text'),
    date: __('Date'),
    number: __('Number'),
    link_button: __('Button'),
    single_select: __('Single select'),
    email: __('Email'),
    url: __('URL'),
    phone_number: __('Phone number'),
    user: __('User'),
  }
}
const CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT = 500 // Keep in sync with WallPostProperties::CustomPostProperty::PROPERTY_NAME_LENGTH_LIMIT
const CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT = 30 // Keep in sync with children classes of WallPostProperties::CustomPostProperty
const CUSTOM_PROPERTY_NAME_CHAR_LIMIT_MAP: Record<WallCustomPostProperty['data_type'], number> = {
  link_button: CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT,
  email: CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT,
  url: CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT,
  phone_number: CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT,
  user: CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT,
  number: CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT,
  date: CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT,
  text: CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT,
  single_select: CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT,
}

const getCustomPropertyNameCharLimit = (dataType: WallCustomPostProperty['data_type']): number => {
  switch (dataType) {
    case 'link_button':
    case 'email':
    case 'url':
    case 'phone_number':
    case 'user':
      return CUSTOM_PROPERTY_NAME_CHAR_SHORT_LIMIT
    default:
      return CUSTOM_PROPERTY_NAME_CHAR_LONG_LIMIT
  }
}

export function getCustomNumberFieldValueForDisplay(options: {
  propertySettings: Pick<
    WallCustomPostNumberProperty,
    'number_display_format' | 'decimal_places' | 'thousand_separators'
  >
  value: PostCustomPropertyValue
  locale?: string
}): string {
  const propertySettings = options.propertySettings
  const propertyValue = options.value as number

  const displayFormatSettings: { locale?: string; numberStringModifier?: (numberString: string) => string } = (() => {
    switch (propertySettings.number_display_format) {
      case 'local':
        return {
          locale: options.locale, // if undefined, would use platform's default locale
        }
      case 'comma_period':
        return {
          locale: 'en-US',
        }
      case 'period_comma': {
        return {
          locale: 'de-DE',
        }
      }
      case 'space_comma': {
        // format as 1.000.000,32, then replace . with space
        return {
          locale: 'de-DE',
          numberStringModifier: (intlString: string) => intlString.replace(/\./g, ' '),
        }
      }
      case 'space_period': {
        // format as 1,000,000.32, then replace , with
        return {
          locale: 'en-US',
          numberStringModifier: (intlString: string) => intlString.replace(/,/g, ' '),
        }
      }
      default:
        throw new Error(`Unsupported number display format: ${propertySettings.number_display_format}`)
    }
  })()

  const decimalPlaceSettings: Intl.NumberFormatOptions | undefined = (() => {
    if (propertySettings.decimal_places === 'as_entered') {
      return {
        // 20 is max allowed setting
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumfractiondigits
        maximumFractionDigits: 20,
      }
    }
    if (!Number.isInteger(propertySettings.decimal_places)) {
      throw new Error(`Unsupported decimal place setting: ${propertySettings.decimal_places}`)
    }
    return {
      minimumFractionDigits: propertySettings.decimal_places,
      maximumFractionDigits: propertySettings.decimal_places,
    }
  })()

  const thousandSeparatorSettings: Intl.NumberFormatOptions =
    propertySettings.thousand_separators == null || propertySettings.thousand_separators
      ? { useGrouping: true }
      : { useGrouping: false }

  const numString = propertyValue.toLocaleString(displayFormatSettings.locale, {
    ...decimalPlaceSettings,
    ...thousandSeparatorSettings,
  })

  if (displayFormatSettings.numberStringModifier != null) {
    return displayFormatSettings.numberStringModifier(numString)
  }
  return numString
}

const getPostPropertyFormattedValue = (options: {
  property: WallCustomPostProperty
  value: PostCustomPropertyValue
}): PostCustomPropertyValue => {
  if (options.value == null) return ''

  if (
    isWallCustomPostPropertyOfType(options.property, 'text') ||
    isWallCustomPostPropertyOfType(options.property, 'email') ||
    isWallCustomPostPropertyOfType(options.property, 'url') ||
    isWallCustomPostPropertyOfType(options.property, 'phone_number')
  ) {
    return options.value as string
  }

  if (isWallCustomPostPropertyOfType(options.property, 'user')) {
    return options.value as UserFieldValue
  }

  if (isWallCustomPostPropertyOfType(options.property, 'date')) {
    return getCustomDateFieldStringForDisplay({
      datetime: options.value as string,
      showTime: options.property.show_time,
      dateDisplayFormat: options.property.date_display_format,
      displayAs: options.property.display_as,
    })
  }

  if (isWallCustomPostPropertyOfType(options.property, 'number')) {
    return getCustomNumberFieldValueForDisplay({
      locale: getHyphenatedCurrentLocale(),
      propertySettings: options.property,
      value: options.value,
    })
  }

  if (isWallCustomPostPropertyOfType(options.property, 'single_select')) {
    const selectedOption = options.property.selection_options.find((option) => option.id === options.value)
    return selectedOption?.name ?? ''
  }

  return ''
}
// #endregion

export {
  areAllAttachmentSettingsValuesEqual,
  ATTACHMENT_TEXT_CHAR_LIMIT,
  ATTACHMENT_TEXT_PLACEHOLDER,
  ATTACHMENT_TEXT_VALIDATION_MESSAGE,
  BODY_DEFAULT_PLACEHOLDER,
  BODY_PLACEHOLDER_CHAR_LIMIT,
  BODY_PLACEHOLDER_VALIDATION_MESSAGE,
  CUSTOM_PROPERTY_DATA_TYPE_DISPLAY_TEXT,
  CUSTOM_PROPERTY_NAME_CHAR_LIMIT_MAP,
  getAttachmentSettingsObject,
  getCustomPropertyNameCharLimit,
  getPostPropertyFormattedValue,
  hasAllCustomPropertiesDefined,
  hasCustomProperties,
  hasSameCustomProperties,
  isPostEmpty,
  isWallCustomPostPropertyOfType,
  postOnlyHasCustomProperties,
  SUBJECT_DEFAULT_PLACEHOLDER,
  SUBJECT_PLACEHOLDER_CHAR_LIMIT,
  SUBJECT_PLACEHOLDER_VALIDATION_MESSAGE,
  willDeletePostAfterRemovingCustomProperty,
}
