/**
 * @file Send a beacon post call to an endpoint with an optional payload.
 * N.B. beacon calls are always POST and are completed even if the tab closes midway.
 * Good way to send telemetry and analytics.
 */
const EMPTY_PAYLOAD = {}

function sendBeacon(endpoint: string, payload: any = EMPTY_PAYLOAD): boolean {
  if (navigator.sendBeacon) {
    if (payload === EMPTY_PAYLOAD) {
      navigator.sendBeacon(endpoint)
    } else {
      navigator.sendBeacon(endpoint, JSON.stringify(payload))
    }
    return true
  }
  return false
}

export default sendBeacon
