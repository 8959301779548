/**
 * @file Call our ping endpoint to indicate that something is working.
 */
import sendBeacon from '@@/bits/beacon'

interface PingOptions {
  rate?: number
  time?: Date
}

const PING_ENDPOINT = 'https://padletcdn.com/ping/94eee807'

function canIgnorePing(sampleRate: number): boolean {
  if (sampleRate > 1) {
    const randomNumber = Math.floor(Math.random() * sampleRate + 1)
    return randomNumber != 1
  }
  return false
}

function ping(tag: string, options: PingOptions = {}): boolean {
  if (options.rate && canIgnorePing(options.rate)) {
    return false
  }
  const endpoint = new URL(PING_ENDPOINT)
  endpoint.searchParams.append('tag', tag)
  if (options.time) endpoint.searchParams.append('time', options.time.getTime().toString())
  return sendBeacon(endpoint.toString())
}

export default ping
